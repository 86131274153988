import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

export class PintanDialogData {
  static PintanDialogData;
  title: string;
  detail: string[];
  image?: { mimetype: string, image: string };
  type?: 'text' | 'password' | 'list' | 'none';
  response?: string;
  list?: { id: string, name: string, accepted: boolean }[];

  static build(r) {
    console.log(r);
    switch (r.type) {
      case 'INST_MSG':
        return {
          title: 'Nachricht der Bank',
          detail: ['Für den Zugang auf ' + r.detail.blz + ' ' + r.detail.login, r.detail.message],
          response: 'ok',
          type: 'none'
        };
      case 'NEED_PT_PIN':
        return {
          title: 'Pin Eingeben',
          detail: ['Für den Zugang auf ' + r.detail.blz + ' ' + r.detail.login, r.detail.message],
          type: 'password'
        };
      case 'NEED_PT_TAN':
        return {
          title: 'TAN Eingeben',
          detail: ['Für den Zugang auf ' + r.detail.blz + ' ' + r.detail.login, r.detail.message]
        };
      case 'NEED_PT_PHOTOTAN':
        return {
          title: 'TAN Eingeben',
          detail: ['Für den Zugang auf ' + r.detail.blz + ' ' + r.detail.login, r.detail.message],
          image: r.detail.image
        };
      case 'NEED_PT_SECMECH':
        return {
          type: 'list',
          title: 'Zweischritt-Anmeldung, Verfahren wählen',
          detail: ['Für den Zugang auf ' + r.detail.blz + ' ' + r.detail.login],
          list: r.detail.list,
          response: r.detail.list[0].id
        };
      default:
        return {
          title: 'Abfrage: ' + r.type,
          detail: [JSON.stringify(r.detail)]
        };
    }
  }
}

@Component({templateUrl: './pintan.dialog.html'})
export class PintanDialog {
  image;

  constructor(sanitizer: DomSanitizer, public dialog: MatDialogRef<PintanDialog>, @Inject(MAT_DIALOG_DATA) public data: PintanDialogData) {
    data.type = data.type || 'text';
    if (data.image) {
      this.image = sanitizer.bypassSecurityTrustResourceUrl('data:' + data.image.mimetype + ';base64,' + data.image.image);
    }
  }
}
