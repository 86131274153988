import {Component} from '@angular/core';
import {AppAuthService} from './common/auth/app-auth.service';

declare let saveAs: any;

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styles: [
    'mat-toolbar { background-color: black; padding-bottom: 4px; color: white}',
    'mat-toolbar.mat-toolbar-single-row { height: 42px; }',
    '.mat-button { line-height: 2em; margin:0 2px; }',
    '.active {background-color: whitesmoke; color: black}'
  ]
})
export class MainNavComponent {


  constructor(public authService: AppAuthService) {
  }
}

