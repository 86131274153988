import {HttpHandler, HttpHeaders, HttpRequest, HttpResponse, HttpXhrBackend} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {catchError, map, mergeMap, take, tap} from 'rxjs/operators';
import {gitVersion} from '../../version';
import {AppAuthService} from './auth/app-auth.service';

@Injectable({providedIn: 'root'})
export class LoaderHttpXhrBackend implements HttpHandler {

  version = new BehaviorSubject<{ server: string, client: string }>({server: null, client: gitVersion});

  constructor(private backend: HttpXhrBackend, private authService: AppAuthService) {
  }

  handle<T>(req: HttpRequest<any>) {
    return this.authService.user$.pipe(
      take(1),
      map(user => user != null ? {Authorization: 'Bearer ' + user.token} : {}),
      mergeMap((headers) =>
        this.backend.handle(new HttpRequest(req.method, req.url, req.body,
          {
            headers: new HttpHeaders({...(headers)}),
            responseType: req.responseType
          }))),
      tap((res) => {
        if (res instanceof HttpResponse) {
          this.version.next({server: res.headers.get('x-application-version'), client: gitVersion});
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }
}

export function buildQuery(query: { [key: string]: any }): string {
  const keys = query ? Object.keys(query).filter(key => query[key] != null) : [];

  if (keys.length > 0) {
    return '?' + keys.map((key) => (key + '=' + query[key])).join('&');
  }
  return '';
}
