import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import * as Sentry from '@sentry/browser';
import 'hammerjs';
import {ToastrModule} from 'ngx-toastr';

import {AppComponent} from './app.component';
import {AppCommonModule, CustomErrorHandler} from './common/app-common.module';
import {AuthRoutingModule} from './common/auth/auth-routing.module';
import {MainNavComponent} from './main-nav.component';
import {PintanDialog} from './pintan.dialog';

Sentry.init({
  dsn: 'https://688585e1218f417eb8aa305abe932b6d@sentry.io/1785144'
});

const ROUTES: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/account'},
//  {path: 'buchungen', loadChildren: () => import('./buchung/buchung.routing.module').then(mod => mod.BuchungRoutingModule)},
  {path: 'account', loadChildren: () => import('./account/account-routing.module').then(mod => mod.AccountRoutingModule)},
  {path: 'user', loadChildren: () => import('./user/user-routing.module').then(mod => mod.UserRoutingModule)},
  {path: 'setting', loadChildren: () => import('./setting/setting-routing.module').then(mod => mod.SettingRoutingModule)},
  {path: 'credentials', loadChildren: () => import('./credentials/credentials-routing.module').then(mod => mod.CredentialsRoutingModule)},
//  {path: 'zuordnungen', loadChildren: () => import('./automatik-zuordnung/automatik-zuordnung.routing.module').
//  then(mod => mod.AutomatikZuordnungRoutingModule)},
//  {path: 'report', loadChildren: () => import('./auswertung/auswertung.routing.module').then(mod => mod.AuswertungRoutingModule)}
];

@NgModule({
  declarations: [MainNavComponent, AppComponent, PintanDialog],
  imports: [
    AppCommonModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 5000,
      enableHtml: true,
      positionClass: 'toast-bottom-right'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AuthRoutingModule,
    LoadingBarRouterModule,
    RouterModule.forRoot(ROUTES, {onSameUrlNavigation: 'reload'})
  ],
  providers: [
    {provide: ErrorHandler, useClass: CustomErrorHandler},
  ],
  bootstrap: [AppComponent],
  entryComponents: [PintanDialog]
})
export class AppModule {
}
