import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'delete-confirm-button',
  templateUrl: 'delete-confirm-button.html',
  styles: ['mat-card-actions{ text-align: center}']
})
export class DeleteConfirmButtonComponent {
  @Input() name: string;
  @Input() title: string;
  @Input() disabled: boolean;
  @Output() onDelete = new EventEmitter<any>();
}
