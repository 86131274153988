import {CdkTableModule} from '@angular/cdk/table';
import {CommonModule, registerLocaleData} from '@angular/common';
import {HttpClientModule, HttpHandler} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import {RouterModule} from '@angular/router';
import {MdePopoverModule} from '@material-extended/mde';
import {AppAuthService} from './auth/app-auth.service';
import {AutotrimDirective} from './autotrim.directive';
import {CustomErrorHandler} from './custom-error.handler';
import {DeleteConfirmButtonComponent} from './delete-confirm-button';
import {GlobalService} from './global.service';
import {KeysPipe} from './keys.pipe';
import {LoaderHttpXhrBackend} from './loader.http.xhr.backend';

// const localeDe: any = require('@angular/common/locales/de.js');
// registerLocaleData(localeDe.default);

export {CustomErrorHandler};

registerLocaleData(localeDe, 'de-DE', localeDeExtra);


@NgModule({
  declarations: [DeleteConfirmButtonComponent, AutotrimDirective, KeysPipe],
  imports: [
    CommonModule, FormsModule, RouterModule, HttpClientModule,
    CdkTableModule, MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule,
    MatChipsModule, MatStepperModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatGridListModule,
    MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule,
    MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule,
    MatToolbarModule, MatTooltipModule, MdePopoverModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})

  ],
  providers: [
    AppAuthService, GlobalService, LoaderHttpXhrBackend,
    {provide: HttpHandler, useClass: LoaderHttpXhrBackend},
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: LOCALE_ID, useValue: 'de-DE'},
  ],
  exports: [
    CommonModule, FormsModule, RouterModule, HttpClientModule, CdkTableModule, MatAutocompleteModule,
    MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatStepperModule,
    MatDatepickerModule, MatDialogModule, MatExpansionModule, MatGridListModule, MatPaginatorModule, MatIconModule,
    MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatTooltipModule, MatProgressBarModule,
    MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatTableModule, MatTabsModule, MatToolbarModule,
    MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
    DeleteConfirmButtonComponent, MdePopoverModule, ReactiveFormsModule, AutotrimDirective, KeysPipe,
  ],
  entryComponents: []
})
export class AppCommonModule {
}
