import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {GlobalData} from '../model';

@Injectable()
export class GlobalService {

  global: Observable<GlobalData>;

  constructor(private http: HttpClient) {
    this.global = this.http.get<GlobalData>('/api/global')
      .pipe(shareReplay(1, 60000));
  }
}
