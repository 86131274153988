import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/browser';
import {ToastrService} from 'ngx-toastr';
import * as StackTrace from 'stacktrace-js';
import {environment} from '../../environments/environment';
import {AppAuthService} from './auth/app-auth.service';

@Injectable({providedIn: 'root'})
export class CustomErrorHandler implements ErrorHandler {

  reportSent = false;

  constructor(private injector: Injector) {
  }

  handleError(error: any) {
    error = error.originalError || error;
    if (environment.production) {
      const eventId = Sentry.captureException(error);
      Sentry.showReportDialog({eventId});
    }

    if (error.rejection) {
      error = error.rejection;
    }

    if (error instanceof HttpErrorResponse) {
      let msg = '';
      if ((<any> error).userMessage) {
        msg = msg + (<any> error).userMessage + '<br/>';
      }
      this.handleErrorResponse(error, msg);
      return;
    }
    const router = this.injector.get(Router);

    let user;
    try {
      user = this.injector.get(AppAuthService).user.login;
    } catch {
    }

    const errorLoader = document.createElement('div');
    errorLoader.className = 'error-report-box';
    errorLoader.innerHTML = '<h2>Es ist ein Fehler aufgetreten</h2>Informationen werden zusammengestellt, bitte warten...';
    document.body.appendChild(errorLoader);

    const details = JSON.stringify({user, url: router.url, message: error.toString()});

    StackTrace.fromError(error).then((stackframes) => {
        if (!this.reportSent) {
          StackTrace.report(stackframes, '/api/report-error', details);
          this.reportSent = true;
        }
      const loaders = <HTMLCollectionOf<HTMLDivElement>> document.getElementsByClassName('loader-spinner');
        if (loaders.length > 0) {
          loaders[0].style.display = 'none';
        }

        let report = errorLoader;
      const routerOutlets = <HTMLCollectionOf<HTMLDivElement>> document.getElementsByTagName('router-outlet');
        if (routerOutlets.length > 0) {
          report = routerOutlets[0];
          errorLoader.style.display = 'none';
        }

        report.innerHTML = `
                  <div style="margin:40px 10px 100px">
                    <h3>Fehler:</h3>  ${error}
                    <h3>Adresse:</h3> ${router.url}
                    <h3>Details:</h3> <ul><li>${stackframes.map((sf) => sf.toString()).join('</li><li>')}</li></ul>
                    
                    <hr/>
                    <a class="mat-stroked-button mat-button-base"  href="/"   style="width:200px">
                        <span class="mat-button-wrapper">zur Startseite</span>
                    </a>
                  </div>`;
      }
    );
    throw error;
    /*
      if (error instanceof TypeError) {
        msg = msg + '<br/>' + error.name + '<br/>' + error.message;
      } else {
        if (error instanceof Error) {
          msg = msg + '<br/>' + error.name + '<br/>' + error.message;
        } else {
          msg = msg + '<br/>' + error.name + '<br/>' + error.statusText + '<br/>' + error.message;
        }
      }
        this.toastr.error(msg, 'Unerwarteter Fehler', {disableTimeOut: true, closeButton: true});
      }
      */
  }

  private handleErrorResponse(response: HttpErrorResponse, userMessage: string) {
    let msg = '';
    let title = `Server-Fehler ${response.status}`;
    if (response.headers.get('content-type') === 'application/json') {
      const content = response.error;
      const lastMessage = content.detail && content.detail.length ? content.detail[content.detail.length - 1] : {};
      msg = content.type + '\n\n' + content.message;
      if (content.type === 'java.lang.RuntimeException') {
        msg = content.message;
      } else if (content.type.startsWith('javax.persistence.')) {
        msg = lastMessage.type + ' ' + lastMessage.message;
        title = 'Datenbank-Fehler';
      } else if (content.type === 'com.google.inject.extensions.security.NotInRoleException') {
        msg = lastMessage.message
          .replace('invalid role to access public ', '')
          .replace(new RegExp('Long', 'g'), 'L')
          .replace(new RegExp('long', 'g'), 'L')
          .replace(new RegExp('int', 'g'), 'I')
          .replace(new RegExp('java.lang.', 'g'), '')
          .replace(new RegExp('java.util.', 'g'), '');
        title = 'Permission denied';
      }
    }
    this.injector.get(ToastrService).error([userMessage, response.statusText, response.message,
        (response.error ? response.error.message : ''), msg].join('<br/>'),
      title, {timeOut: 0, disableTimeOut: true});
  }
}
