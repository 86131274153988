import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppAuthService} from './app-auth.service';

@Component({template: ''})
export class LogoutComponent implements OnInit {

  constructor(private authService: AppAuthService, private router: Router) {
  }

  ngOnInit() {
    this.authService.logout().subscribe(() => this.router.navigate(['/']));
  }
}
