import {HttpClient, HttpHeaders, HttpXhrBackend} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {of, ReplaySubject, Subject} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

const jwt = new JwtHelperService();

class AuthUser {
  login: string;
  token: string;
  roles: string[];

  constructor(res: AuthUser) {
    this.token = res.token;
    try {
      const decodeToken = jwt.decodeToken(this.token);
      this.login = decodeToken.sub;
      this.roles = decodeToken.a;
    } catch (e) {
      console.error('failed decoding token:', e);
      throw e;
    }
  }
}

@Injectable()
export class AppAuthService {
  user: AuthUser;

  user$: Subject<AuthUser> = new ReplaySubject<AuthUser>(1);

  private http: HttpClient;

  constructor(backend: HttpXhrBackend) {
    this.http = new HttpClient(backend);

    this.user$.subscribe(user => {
      if (user) {
        localStorage.setItem('auth-token', user.token);
      } else {
        localStorage.removeItem('auth-token');
      }
      this.user = user;
    });

    const token = localStorage.getItem('auth-token');
    if (token) {
      this.check(token).subscribe(() => true);
    } else {
      this.user$.next(null);
    }
  }

  get isLoggedIn() {
    return this.user;
  }

  get isAdmin() {
    return this.user && this.user.roles && this.user.roles.indexOf('ADMIN') > -1;
  }

  public checkIdToken(user) {
    return this.http
      .post<AuthUser>('/api/auth/login', {idToken: user.idToken})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err) => {
          this.user$.next(null);
          throw err;
        })
      );
  }

  check(token: string) {
    return this.http
      .get<AuthUser>('/api/auth/refresh', {headers: new HttpHeaders({Authorization: 'Bearer ' + token})})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err) => {
          if (err.status !== 401 && err.status !== 403) {
            throw err;
          }
          this.user$.next(null);
          return of(null);
        })
        // shareReplay(1, 60000)
      );
  }

  login(username: string, password: string) {
    localStorage.removeItem('auth-token');
    return this.http
      .post<AuthUser>('/api/auth/login', {username, password})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err) => {
          this.user$.next(null);
          throw err;
        })
      );
  }

  logout() {
    localStorage.removeItem('auth-token');
    return of(null).pipe(
      tap(() => this.user$.next(null))
    );
  }
}
