import {Directive, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
  selector: '[autotrim]',
  host: {'(change)': 'ngOnInit()', '(focus)': 'ngOnInit()', '(blur)': 'ngOnInit()'}
})
export class AutotrimDirective implements OnInit {

  constructor(private model: NgModel) {
  }

  ngOnInit() {
    if (this.model.value != null && typeof this.model.value.trim === 'function') {
      const newValue = this.model.value.trim();
      if (this.model.value !== newValue) {
        this.model.control.patchValue(newValue);
      }
      // this.model.viewToModelUpdate(newValue);
      // this.model.valueAccessor.writeValue(newValue);
    }
  }
}
